<template>
	<div class="CouponInfoSSS">
		
		<div class="Top">
			<div class="Left">
				运费模板详情 
				<i style="font-style: normal;color: rgba(0,0,0,0.2);" v-if="FeeTemplate.CreatedAt != undefined">{{'编号('+FeeTemplate.Id+')'}}</i>
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				
				
				<li>
					<span class="Sel">模板名称</span>
					<em  class="Sel">
						<el-input v-model="FeeTemplate.Name" style="width: 320px;"></el-input>
					</em>

				</li>
				
				<li>
					
					<span class="Sel">计费方式</span>
					<em  class="Sel" style="display: flex;">
						<el-radio v-model="FeeTemplate.RuleType" label="num">按件计数</el-radio>
						<el-radio v-model="FeeTemplate.RuleType" label="weight">按重量计数</el-radio>
					</em>
				
				</li>
				
				
				<li>
					<span class="Sel"></span>
					<em  class="Sel" style="display: block;flex: 1;">
						<div class="ExpressArea">
							<div class="Title">
								<el-button size="mini" type="primary" @click="NewRule">新增可配送区域</el-button>
								<span style="padding-left: 20px;font-size: 0.85rem;color: rgba(0,0,0,0.4);">如果某区域被选中，则代表对所有下级区域生效，无需独立选取下级区域</span>
							</div>
							<div class="Rule" style="font-size: 0.85rem;color: rgba(0,0,0,0.4);text-align: center;">
								<div class="Area" style="text-align: left;">可配送区域</div>
								<div class="First">首{{FeeTemplate.RuleType == 'num' ? '件':'重'}}({{FeeTemplate.RuleType == 'num' ? '个':'公斤'}})</div>
								<div class="FirstPrice">首费(元)</div>
								<div class="Step">续{{FeeTemplate.RuleType == 'num' ? '件':'重'}}({{FeeTemplate.RuleType == 'num' ? '个':'公斤'}})</div>
								<div class="StepPrice">续费(元)</div>
								<div class="Free">包邮{{FeeTemplate.RuleType == 'num' ? '个':'公斤'}}数</div>
								<div class="Action">操作</div>
							</div>
							<div class="Rule" v-for="(rule,ruleI) in FeeTemplate.Rules" :key="ruleI">
								
								<div class="Area">
									
									<el-cascader
									size="mini"
									v-model="rule.area"
									    :options="LevelOneArea"
									    :props="cascader_props"
									    clearable></el-cascader>
									
								</div>
								<div class="First">
									<el-input-number size="mini"  style="width: 120px;"  :precision="0"  v-model="rule.first" :min="1" :max="99999999" :controls="false"></el-input-number>
								</div>
								<div class="FirstPrice">
									<el-input-number size="mini"  style="width: 120px;"  :precision="2"  v-model="rule.first_price" :min="0.00" :max="99999999.00" :controls="false"></el-input-number>
								</div>
								<div class="Step">
									<el-input-number size="mini"  style="width: 120px;"  :precision="0"  v-model="rule.step" :min="1" :max="99999999" :controls="false"></el-input-number>
								</div>
								<div class="StepPrice">
									<el-input-number size="mini"  style="width: 120px;"  :precision="2"  v-model="rule.step_price" :min="0.00" :max="99999999.00" :controls="false"></el-input-number>
								</div>
								<div class="Free">
									<el-input-number size="mini"  style="width: 120px;"  :precision="0"  v-model="rule.free_num" :min="1" :max="99999999" :controls="false"></el-input-number>
								</div>
								<div class="Action">
									<el-button size="mini" icon="el-icon-close" circle @click="delRule(ruleI)"></el-button>
								</div>
							</div>
						</div>
					</em>

				</li>
			
				
				
				<li v-if="FeeTemplate != null && FeeTemplate.Id != ''">
					<span class="Sel">创建时间</span>
					<em class="Sel">
						{{FeeTemplate.CreatedAt}}
					</em>
				</li>
				
				<li v-if="FeeTemplate != null && FeeTemplate.Id != ''">
					<span class="Sel">最后修改时间</span>
					<em class="Sel">
						{{FeeTemplate.UpdatedAt}}
					</em>
				</li>
				
				<li>
					<span class="Sel"></span>
					<em class="Sel">
						<el-button v-if="FeeTemplate != null && FeeTemplate.Id != ''" @click="NewTemplate()" type="danger">提交修改</el-button>
						<el-button v-if="FeeTemplate != null && FeeTemplate.Id == ''" @click="NewTemplate()" type="danger">创建运费模板</el-button>
					</em>
				</li>
			</div>
		</div>
		
	</div>
</template>

<script>
	import {Select,Option,Dialog,Upload,InputNumber,DatePicker,Switch,Radio,Cascader} from 'element-ui'
	export default {
	  name: 'MyExpressFeeTemplate',
	  props: {
	  },
	  data() {
	      return {
			  FeeTemplate:{
				  Id:'',
				  Name:'',
				  RuleType:'num',
				  Rules:[]
			  },
			  LevelOneArea:[
				  {
					value: 1,
					label: '全国',
					children: [],
				}
			  ],//一级地址
			  cascader_props: {
				  multiple: true,
				  checkStrictly: true,
					lazy: true,
					lazyLoad:this.lazyLoad
			    },
			}
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
		'el-input-number':InputNumber,
		'el-date-picker':DatePicker,
		'el-switch':Switch,
		'el-radio':Radio,
		'el-cascader':Cascader,
	  },
	  created() {
		this.GetCoupon(this.$route.params.Id)
	  },
	  methods:{
		  delRule(_ind){
			  this.FeeTemplate.Rules.splice(_ind,1)
		  },
		  dbAreaShow(){ //地理位置回显
			  
			  //将数据库中的数据，按照当前选中情况，分层注入到系统中
			  let areaIds = []
			  this.FeeTemplate.Rules.forEach(rule => {
				  rule.area.forEach(item => {
					  let isIn = false
					  for(var i=0;i<areaIds.length;i++){
						  if(areaIds[i] == item){
							  isIn = true
						  }
					  }
					  if(!isIn){
						  areaIds.push(item)
					  }
				  })
			  })
			  
			  if(areaIds.length == 0){
				  return
			  }
			  
			  
			  let data = {Service:'Goods',Class: 'Address',Action: 'AreaList',Ids:JSON.stringify(areaIds),Page:1,PageSize:areaIds.length}
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
				
				//第二层级 - 省级信息注入
				let tlist = res.Data.AreaList
				let that = this
				
				tlist.forEach(area => {
					if(area.Pid === 1){
						that.LevelOneArea[0].children.push({
							value: area.Id,
							label: area.Name,
							children: [],
						})
					}
				})
				
				//第二层级 - 市级信息注入
				that.LevelOneArea[0].children.forEach(province => {
					
					for(var i=0;i<tlist.length;i++){
						if(tlist[i].Pid === province.value){
							province.children.push({
								value: tlist[i].Id,
								label: tlist[i].Name,
								children: [],
							})
						}
					}
					
				})
			  							
			  })
			  
		  },
		  lazyLoad(node, resolve) {
			console.log('懒加载',node)
			let data = {Service:'Goods',Class: 'Address',Action: 'AreaList',Pid:node.value,Page:1,PageSize:200}
			this.$post(this.$store.getters.getApiHost,data)
			.then((res) => {
				
				if(res.ErrorId != 0){
					this.$message(res.Msg)
					return
				}
				
				let tlist = []
				res.Data.AreaList.forEach(area => {
					//判定是否存在于当前节点
					let isIn = false
					for(var i=0;i<node.children.length;i++){
						if(node.children[i].value === area.Id){
							isIn = true
						}
					}
					if(!isIn){
						tlist.push(area)
					}
				})
				
				let nodes = tlist.map(item => ({
					value: item.Id, // 
					label: item.Name,
					leaf: node.level >= 2
				}))
				
				resolve(nodes)
										
			})
		  },
		  NewRule(){
			  
			  let rule = {
				  area:'',//支持区域
				  first:'',//首件
				  first_price:0.00,//首件费用
				  step:1,//续件步进
				  step_price:0.00,//续件费用
				  free_num:9999999,//达到多少数字后包邮
			  }
			  
			  this.FeeTemplate.Rules.push(rule)
			  
		  },
		    GetCoupon(_id){
				if(_id == '' || _id == 'add'){
					return
				}
		  		let data = {Service:'Goods',Class: 'ExpressFeeTemplate',Action: 'Get',Id:_id,}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.FeeTemplate = res.Data
					
					this.dbAreaShow()
					
		  		})
		    }, 
			NewTemplate(){
					
					this.FeeTemplate.Name = this.$Trim(this.FeeTemplate.Name)
					if(this.FeeTemplate.Name.length < 1){
						this.$message.error('必须输入运费模板名称')
						return
					}
					
					let rules = this.FeeTemplate.Rules
					if(rules.length == 0){
						this.$message.error('请至少创建一条配送区域的运费规则')
						return
					}
					
					for(var i=0;i<rules.length;i++){
						if(rules[i].first <1){
							this.$message.error('运费规则中首件/首重数量不能小于1')
							return
						}
						if(rules[i].first_price < 0){
							this.$message.error('运费规则中首件/首重的运费不能小于0')
							return
						}
						if(rules[i].step <1){
							this.$message.error('运费规则中续件/续重数量不能小于1')
							return
						}
						if(rules[i].step_price < 0){
							this.$message.error('运费规则中续件/续重的运费不能小于0')
							return
						}
						if(rules[i].free_num < 1){
							this.$message.error('运费规则中续件/续重的包邮条件不能小于0')
							return
						}
					}
					
					
					let data = {
						Service:'Goods',
						Class: 'ExpressFeeTemplate',
						Action: 'Add',
						Name:this.FeeTemplate.Name,
						RuleType:this.FeeTemplate.RuleType,
						Rules:JSON.stringify(rules),
					}

					if(this.FeeTemplate.CreatedAt != undefined){
						data.Id = this.FeeTemplate.Id
						data.Action = 'Update'
					}
					
					this.$post(this.$store.getters.getApiHost,data)
					.then((res) => {
						
						if(res.ErrorId != 0){
							this.$message.error(res.Msg)
							return
						}
						if(this.FeeTemplate.CreatedAt != undefined){
							this.$message.success('编辑成功')
						}else{
							this.$message.success('创建成功')
						}
						
						this.$Jump('/my/express_fee_template/list')
						
					})
					.catch(function (response) {
						this.$message('网络请求错误')
					})
			}
	  }
	}
</script>

<style>
.CouponInfoSSS{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.CouponInfoSSS .Top{
	display: flex;
	align-items: center;
}
.CouponInfoSSS .Top .Left{
	
}
.CouponInfoSSS .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.CouponInfoSSS .List{
	margin-top: 20px;	
}
.CouponInfoSSS .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.CouponInfoSSS .List .One li {
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.CouponInfoSSS .List .One li  span.Sel{
	width: 120px;
	color: rgba(0,0,0,0.3);
	text-align: right;
	padding-right: 10px;
}
.CouponInfoSSS .List .One li  em.Sel{
	width: 200px;
}
.CouponInfoSSS .List .One li  i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }
  
.ql-editor{
        height:360px;
    }
	
	.PreviewImg{
		display: inline-block;
		width: 40px;
		height: 40px;
		border:1px dotted rgba(0,0,0,0.1);
		position: relative;
		margin-right: 5px;
	}
	.PreviewImg:hover{
		border:1px dotted rgba(228,0,0,0.7);
	}
	.PreviewImg i.Remove{
		display: none;
	}
	.PreviewImg:hover i.Remove{
		display: inline-block;
		position: absolute;
		bottom: 0px;
		right: 0px;
		background-color: rgba(228,0,0,0.8);
		padding: 2px 5px;
		border-radius: 2px;
		color: #FFFFFF;
		cursor: pointer;
		font-style: normal;
	}
	.PreviewImg .ShowContent{
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
	}
	.PreviewImg .ShowContent img{
		width: 90%;
		height: 90%;
	}
	
	.ExpressArea .Rule{
		display: flex;
		align-items: center;
		margin-top: 10px;
		text-align: left;
	
	}
	.ExpressArea .Rule .Area{
		width: 200px;
	}
	.ExpressArea .Rule .First{
		width: 120px;
		margin-right: 5px;
	}
	.ExpressArea .Rule .FirstPrice{
		width: 120px;
		margin-right: 5px;
	}
	.ExpressArea .Rule .Step{
		width: 120px;
		margin-right: 5px;
	}
	.ExpressArea .Rule .StepPrice{
		width: 120px;
		margin-right: 5px;
	}
	.ExpressArea .Rule .Free{
		width: 120px;
		margin-right: 5px;
	}
	.ExpressArea .Rule .Action{
		width: 80px;
		text-align: center;
	}
</style>
